import { Link, useLocation } from 'react-router-dom'
import Logo from 'assets/images/ISILOGOPNG.png'
import { HiMenuAlt3 } from 'react-icons/hi'
import { CgClose } from 'react-icons/cg'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { UserContext } from 'Context/UserContext'
import { LuLogOut } from 'react-icons/lu'
import { FaUser } from 'react-icons/fa'
import { GrDown } from 'react-icons/gr'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'
import { setToken } from 'CommonJS/common'
import { isAdmin, isStudent, isSuperAdmin } from 'CommonJS/roleHelper'

export default function Header() {
  const [toggle, setToggle] = useState(false)
  const { data } = useContext(UserContext)
  const { Logout } = useContext(AuthContext)
  const [isImpersonated, setIsImpersonated] = useState(false)
  const location = useLocation()

  const checkImpersonated = (): boolean => {
    let user = { impersonatedBy: { token: null, user: { id: null } } }
    try {
      user = JSON.parse(localStorage.getItem('user') ?? '{}')
    } catch (e) {
      //
    }

    if (!user?.impersonatedBy) return false
    const impersonatedBy = user?.impersonatedBy

    return Boolean(impersonatedBy?.token && impersonatedBy?.user?.id)
  }

  useEffect(() => {
    setIsImpersonated(checkImpersonated())
  }, [data.id])

  const exitImpersonate = () => {
    const userContextData = data
    const studentId = data?.student?.id
    http
      .fetch({
        method: 'post',
        path: `impersonate/${studentId}`,
        options: {
          params: {
            exit: true
          }
        }
      })
      .catch((error) => {
        toast(error?.data?.message || 'Internal server error.', 'error')
      })
      .then(async (response) => {
        toast(response?.data.message, 'success')
        if (response && response.status === 200) {
          const adminUser = userContextData.impersonatedBy?.user
          const adminToken = userContextData.impersonatedBy?.token as string
          await localStorage.setItem('user', JSON.stringify(adminUser))
          await setToken(adminToken)
          if (location.pathname.includes('/visa-dashboard')) {
            window.location.href = `/admin/visa-dashboard/${studentId}`
          } else {
            window.location.href = `/admin/student-dashboard/${studentId}`
          }
        }
      })
  }

  return (
    <header className='sticky top-0 px-5 bg-primary lg:px-4 lg:py-1 z-[150] visa-portal-theme-bg visa-portal-font-bg'>
      <div className='flex justify-between items-center z-50'>
        <div className='logo bg-white rounded-md'>
          <Link to='/'>
            <img src={Logo} alt='log' className='lg:w-28' />
          </Link>
        </div>

        <HiMenuAlt3 className='w-10 h-10 text-white cursor-pointer hidden lg:block' onClick={() => setToggle(true)} />
        <div className={`fixed inset-0 bg-black/70 hidden z-50 ${toggle ? '!block' : ''} `} onClick={() => setToggle(false)}></div>
        <div
          className={`lg:fixed lg:top-0 lg:bottom-0 flex justify-end lg:justify-start lg:left-0 lg:bg-gray-100 lg:border-r lg:border-gray-300 lg:z-50 lg:p-5 lg:w-[15rem] relative lg:-translate-x-full lg:transition-all lg:duration-500 ${
            toggle ? '!translate-x-0' : ''
          }`}
        >
          <CgClose
            className='w-10 h-10 text-primary absolute top-3 right-3 cursor-pointer hidden lg:block'
            onClick={() => setToggle(false)}
          />

          <ul className='flex items-center [&>li]:py-6 [&>*_a]:text-xl [&>*]:ml-7 [&>*_a]:text-white lg:flex-col lg:items-start [&>*_a]:lg:py-2 [&>*]:lg:ml-0 [&>*_a]:lg:text-primary lg:mt-7 [&>li]:lg:py-0 [&>*li>a]:flex [&>*>a]:lg:block lg:w-full [&>li]:lg:w-full [&>*_a]:lg:w-full'>
            {isStudent(data?.roles) ? (
              <li>
                <Link
                  onClick={() => setToggle(false)}
                  to='/'
                  className={location.pathname === '/dashboard' ? '!text-[var(--menu-color)]' : 'hover:!text-[var(--menu-color)]'}
                >
                  Dashboard
                </Link>
              </li>
            ) : (
              <li className='relative py-4 group'>
                <Link
                  to='#'
                  className={`flex gap-x-2 items-center lg:justify-between lg:!hidden ${
                    ['/dashboard', '/dashboard-visa'].includes(location.pathname)
                      ? '!text-[var(--menu-color)]'
                      : 'hover:!text-[var(--menu-color)]'
                  }`}
                >
                  Dashboard <GrDown />
                </Link>
                <ul className='hidden group-hover:block lg:block absolute top-full bg-white overflow-hidden pt-1 [&_a]:px-3 right-0 [&_a]:text-primary [&_a]:py-1 [&>*_a:hover]:visa-portal-theme-bg [&>*_a:hover]:text-white [&>*_a:hover]:lg:bg-transparent [&>*_a:hover]:lg:text-primary border border-slate-200 shadow-lg rounded-md min-w-max max-w-40 [&_a]:text-xl lg:w-full lg:shadow-none lg:border-0 lg:bg-transparent lg:static'>
                  <li className='border-b lg:border-0 lg:!rounded-none'>
                    <Link
                      onClick={() => setToggle(false)}
                      to='/dashboard'
                      className={`flex gap-x-2 items-center lg:!px-0 ${
                        ['/dashboard'].includes(location.pathname)
                          ? 'lg:!bg-transparent !visa-portal-theme-bg !text-white md:!text-secondary'
                          : ''
                      }`}
                    >
                      Admission Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setToggle(false)}
                      to='/dashboard-visa'
                      className={`flex gap-x-2 items-center lg:!px-0 ${
                        ['/dashboard-visa'].includes(location.pathname)
                          ? 'lg:!bg-transparent !visa-portal-theme-bg !text-white md:!text-secondary'
                          : ''
                      }`}
                    >
                      Visa Dashboard
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {!isStudent(data?.roles) && (
              <li className='relative py-4 group'>
                <Link
                  to='#'
                  className={`flex gap-x-2 items-center lg:justify-between lg:!hidden ${
                    ['/admin/student', '/admin/visa-student'].includes(location.pathname)
                      ? '!text-[var(--menu-color)]'
                      : 'hover:!text-[var(--menu-color)]'
                  }`}
                >
                  Students <GrDown />
                </Link>
                <ul className='hidden group-hover:block lg:block absolute top-full bg-white overflow-hidden pt-1 [&_a]:px-3 right-0 [&_a]:text-primary [&_a]:py-1 [&>*_a:hover]:visa-portal-theme-bg [&>*_a:hover]:text-white [&>*_a:hover]:lg:bg-transparent [&>*_a:hover]:lg:text-primary border border-slate-200 shadow-lg rounded-md min-w-max max-w-40 [&_a]:text-xl lg:w-full lg:shadow-none lg:border-0 lg:bg-transparent lg:static'>
                  <li className='border-b lg:border-0 lg:!rounded-none'>
                    <Link
                      onClick={() => setToggle(false)}
                      to='/admin/student'
                      className={`flex gap-x-2 items-center lg:!px-0 ${
                        ['/admin/student'].includes(location.pathname)
                          ? 'lg:!bg-transparent !visa-portal-theme-bg !text-white md:!text-secondary'
                          : ''
                      }`}
                    >
                      Admission Students
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setToggle(false)}
                      to='/admin/visa-student'
                      className={`flex gap-x-2 items-center lg:!px-0 ${
                        ['/admin/visa-student'].includes(location.pathname)
                          ? 'lg:!bg-transparent !visa-portal-theme-bg !text-white md:!text-secondary'
                          : ''
                      }`}
                    >
                      Visa Students
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {isSuperAdmin(data?.roles) && (
              <li>
                <Link
                  onClick={() => setToggle(false)}
                  to='/admin/users'
                  className={location.pathname.startsWith('/admin/users') ? '!text-[var(--menu-color)]' : 'hover:!text-[var(--menu-color)]'}
                >
                  Users
                </Link>
              </li>
            )}
            {isAdmin(data.roles) && (
              <li>
                <Link
                  onClick={() => setToggle(false)}
                  to='/admin/setting/insurance-portal'
                  className={
                    location.pathname.startsWith('/admin/setting') ? '!text-[var(--menu-color)]' : 'hover:!text-[var(--menu-color)]'
                  }
                >
                  Settings
                </Link>
              </li>
            )}
            {isStudent(data?.roles) ? (
              <li>
                <Link
                  to='#'
                  onClick={() => {
                    isImpersonated ? exitImpersonate() : Logout()
                  }}
                  className='flex gap-x-2 items-center [var(--menu-color)]px-0 hover:!text-[var(--menu-color)]'
                >
                  <LuLogOut className='lg:hidden' /> {isImpersonated ? 'Exit' : 'Logout'}
                </Link>
              </li>
            ) : (
              <li className='relative py-4 group'>
                <Link
                  to='#'
                  className={`flex gap-x-2 items-center lg:justify-between lg:!hidden ${
                    location.pathname === '/admin/profile' ? '!text-[var(--menu-color)]' : 'hover:!text-[var(--menu-color)]'
                  }`}
                >
                  {data?.name} <GrDown />
                </Link>
                <ul className='hidden group-hover:block lg:block absolute top-full bg-white overflow-hidden pt-1 [&_a]:px-3 right-0 [&_a]:text-primary [&_a]:py-1 [&>*_a:hover]:visa-portal-theme-bg [&>*_a:hover]:text-white [&>*_a:hover]:lg:bg-transparent [&>*_a:hover]:lg:text-primary border border-slate-200 shadow-lg rounded-md min-w-40 [&_a]:text-xl lg:w-full lg:shadow-none lg:border-0 lg:bg-transparent'>
                  <li className='border-b lg:border-0'>
                    <Link onClick={() => setToggle(false)} to='/admin/profile' className='flex gap-x-2 items-center lg:!px-0'>
                      <FaUser className='lg:hidden' /> Profile
                    </Link>
                  </li>
                  <li>
                    <Link to='#' onClick={Logout} className='flex gap-x-2 items-center lg:!px-0'>
                      <LuLogOut className='lg:hidden' /> Logout
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </header>
  )
}
