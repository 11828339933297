import http from 'CommonJS/http'
import { useContext, useEffect, useState } from 'react'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'
import ReactJsPagination from 'react-js-pagination'
import './index.css'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import Loading from 'Components/Loading'
import { toast } from 'Components/Toast/toast'
import { StudentViewData, StudentViews } from './type'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import { forVisaPortalStudentListParam, toTitleCase } from 'CommonJS/common'
import { FaX } from 'react-icons/fa6'
import { CiSquarePlus } from 'react-icons/ci'
import { RolePermissionContext } from 'Context/RolePermission/RolePermissionContext'
import { RolePermissionTypes } from 'Context/RolePermission/RolePermissionTypes'
import { AxiosResponse } from 'axios'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'

export default function GlobalView() {
  const { visaPortalSetting, getVisaPortalSetting } = useContext(AppContext) as AppContextType
  const { roles, getRoles } = useContext(RolePermissionContext) as RolePermissionTypes
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [studentViews, setStudentViews] = useState<StudentViews>()
  const [visaStudentViews, setVisaStudentViews] = useState<StudentViews>()
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState<boolean>(false)
  const [deleteStudentView, setDeleteStudentView] = useState<StudentViewData | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [editView, setEditView] = useState<number | string | null>(null)
  const [viewName, setViewName] = useState<string | null>(null)
  const [viewRoles, setViewRoles] = useState<number[]>([])

  interface EditErrorsType {
    title?: string
    roles?: string
  }
  const [editErrors, setEditErrors] = useState<EditErrorsType>({})

  useEffect(() => {
    const init = async () => {
      await setLoading(true)
      if (Object.keys(visaPortalSetting ?? {})?.length === 0) {
        getVisaPortalSetting()
      }
      if (roles?.length === 0) getRoles()
      getStudentViewData()
      getStudentViewData(1, true)
    }
    init()
  }, [])

  useEffect(() => {
    if (visaPortalSetting) {
      document.documentElement.style.setProperty('--local-theme-color', visaPortalSetting.themeColor)
      document.documentElement.style.setProperty('--local-font-color', visaPortalSetting.fontColor)
    }
  }, [visaPortalSetting])

  const getStudentViewData = async (page?: number, visaPortalView: boolean = false) => {
    const toPage = page ?? pageNumber
    setLoading(true)
    setPageNumber(toPage)
    await http
      .fetch({ path: `student/view?page=${toPage}&global=true`, options: { params: forVisaPortalStudentListParam(visaPortalView) } })
      .catch(({ response }) => {
        toast(response?.data?.message ?? 'Internal Server Error.', 'error')
        setLoading(false)
      })
      .then((response: void | AxiosResponse<{ studentViews: StudentViews }>) => {
        setLoading(false)
        if (response && response.status === 200) {
          if (visaPortalView) {
            setVisaStudentViews(response.data.studentViews)
          } else {
            setStudentViews(response.data.studentViews)
          }
        }
      })
  }

  const handleDeleteStudentView = async () => {
    if (!deleteStudentView) {
      toast('Something went wrong please try again', 'error')
      return
    }
    await setLoading(true)
    await http
      .fetch({ method: 'delete', path: `/setting/global-view/${deleteStudentView.id}` })
      .catch(({ response }) => {
        toast(response?.data?.message ?? 'Internal server error', 'error')
        setLoading(false)
        setConfirmAlertOpen(false)
        setDeleteStudentView(null)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully deleted student view', 'success')
          getStudentViewData(1, deleteStudentView.isVisaDashboard)
          setConfirmAlertOpen(false)
          setDeleteStudentView(null)
        }
      })
    await setLoading(false)
  }

  const handleChange = (studentView: Partial<StudentViewData>) => {
    const newData = {
      ...studentView,
      title: viewName ?? '',
      roles: viewRoles
    }
    let hasErrors = false
    if (!newData.title) {
      hasErrors = true
      setEditErrors((prevVal) => {
        return {
          ...prevVal,
          title: 'The title field is required.'
        }
      })
    }

    if (!newData.roles || newData.roles.length === 0) {
      hasErrors = true
      setEditErrors((prevVal) => {
        return {
          ...prevVal,
          roles: 'The roles field is required.'
        }
      })
    }

    if (hasErrors) {
      return false
    }
    setEditErrors({})
    setLoading(true)
    http
      .fetch({ method: 'post', path: `/setting/global-view/${newData?.id}`, data: newData })
      .catch((error: any) => {
        toast(error.response?.data?.message || 'Internal server error', 'error')
      })
      .then(async (response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success')
          await getStudentViewData(undefined, studentView.isVisaDashboard)

          resetEdit()
        }
      })
  }

  const resetEdit = () => {
    setEditErrors({})
    setEditView(null)
    setViewName(null)
    setViewRoles([])
  }

  const createNewView = (createVisaView: boolean = false) => {
    setLoading(true)
    http
      .fetch({ method: 'post', path: `/setting/global-view/create`, data: { createVisaView } })
      .catch((error: any) => {
        toast(error.response?.data?.message || 'Internal server error', 'error')
      })
      .then(async (response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully created new global view', 'success')
          await getStudentViewData(undefined, createVisaView)

          resetEdit()
        }
      })
  }

  return (
    <>
      {isLoading && <Loading />}
      <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]' data-cy='student-view-page'>
        <div className='flex md:flex-col justify-between items-center md:items-start gap-4'>
          <h1 className='text-3xl font-bold decoration-1 md:w-3/4'>Global Admission Student View</h1>
          <div className='flex gap-3 ml-auto'>
            <button
              className='btn btn-primary flex items-center gap-x-3'
              data-cy='global-admission-view-create'
              onClick={() => createNewView()}
            >
              Create Global Admission View <CiSquarePlus className='w-6 h-6' />
            </button>
            <Link to='/admin/student' className='ml-auto'>
              <button className='btn-primary flex items-center gap-x-2' data-cy='go-back'>
                <IoIosArrowBack />
                Back
              </button>
            </Link>
          </div>
        </div>
        <div className='overflow-auto'>
          <table className='w-full mt-5 lg:w-max' id='global-admission-views-table' data-cy='global-admission-views-table'>
            <thead>
              <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                <th>#</th>
                <th className='w-[30%]'>View Title</th>
                <th className='w-[35%]'>Assigned to role</th>
                <th className='w-[30%]'>Action</th>
              </tr>
            </thead>
            <tbody>
              {studentViews?.data.length === 0 || !studentViews?.data ? (
                <>
                  <tr>
                    <td colSpan={3}>No Global Student View Found</td>
                  </tr>
                </>
              ) : (
                <>
                  {studentViews?.data.map((studentView, index) => (
                    <tr key={studentView.id} data-id={studentView.id}>
                      <td>{(studentViews?.currentPage - 1) * studentViews?.perPage + index + 1}</td>
                      <td>
                        {editView && editView === studentView.id ? (
                          <td>
                            <input
                              type='text'
                              className={`form-input mt-2 min-h-14 max-w-[95%] ${editErrors?.title ? 'border border-red-500' : ''}`}
                              data-cy='global-admission-view-title'
                              defaultValue={studentView?.title}
                              placeholder={`Enter View Title`}
                              onChange={(e) => {
                                setViewName(e.target.value)
                              }}
                              name={'title'}
                            />
                            {editErrors?.title && <span className='text-sm text-red-500'>{editErrors.title}</span>}
                          </td>
                        ) : (
                          <>{studentView?.title}</>
                        )}
                      </td>
                      <td>
                        {editView && editView === studentView.id ? (
                          <div className='flex flex-col'>
                            <FormControl sx={{ m: 1, minWidth: '160px' }}>
                              <InputLabel id='student-views-roles-label'>Roles</InputLabel>
                              <Select
                                className='min-w-[340px] max-w-[340px] role-dropdown-tag-container-parent'
                                data-cy='global-admission-view-roles'
                                error={Boolean(editErrors?.roles)}
                                labelId='student-views-roles-label'
                                id='student-views-roles'
                                multiple
                                autoWidth
                                value={viewRoles}
                                onChange={(event) => {
                                  const { value } = event.target
                                  setViewRoles(value as number[])
                                }}
                                input={<OutlinedInput label='Roles' />}
                                renderValue={(selected) => {
                                  const selectedNames = roles
                                    ?.filter((role) => selected.includes(role.id))
                                    .map((role) => toTitleCase(role.name, '_'))
                                  return (
                                    <>
                                      {selectedNames
                                        ? selectedNames.map((rName) => (
                                            <span key={rName} className='px-3 py-2 text-sm rounded-md bg-primary text-white'>
                                              {rName}
                                            </span>
                                          ))
                                        : ''}
                                    </>
                                  )
                                }}
                              >
                                {roles?.map((role) => {
                                  return (
                                    <MenuItem key={`role_${role.id}`} value={role.id}>
                                      <Checkbox checked={viewRoles?.indexOf(role?.id) > -1} />
                                      <ListItemText primary={toTitleCase(role.name, '_')} />
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                            {editErrors?.roles && <span className='ml-2 text-sm text-red-500'>{editErrors.roles}</span>}
                          </div>
                        ) : (
                          <div className='flex flex-wrap gap-y-2 gap-x-3 max-w-[340px]'>
                            {studentView.roles.map((rName) => (
                              <span key={`${studentView.id}_${rName.name}`} className='px-3 py-2 text-sm rounded-md bg-primary text-white'>
                                {toTitleCase(rName.name, '_')}
                              </span>
                            ))}
                          </div>
                        )}
                      </td>
                      <td>
                        <div className='flex gap-5 items-center'>
                          {editView && editView === studentView.id ? (
                            <div className='flex gap-2 mr-4'>
                              <button
                                className='btn btn-primary flex items-center gap-x-3'
                                data-cy='global-admission-view-save'
                                onClick={() => handleChange(studentView)}
                              >
                                Save <FaPencilAlt size={14} />
                              </button>
                              <button
                                className='btn btn-secondary flex items-center gap-x-3'
                                data-cy='global-admission-view-cancel'
                                onClick={resetEdit}
                              >
                                Cancel <FaX size={14} />
                              </button>
                            </div>
                          ) : (
                            <button
                              className='btn btn-primary flex items-center gap-x-3'
                              data-cy='global-admission-view-edit'
                              onClick={() => {
                                setEditErrors({})
                                setEditView(studentView.id)
                                setViewName(studentView.title)
                                setViewRoles(studentView.roles.map((r) => r.id))
                              }}
                            >
                              Edit <FaPencilAlt size={14} />
                            </button>
                          )}
                          <button
                            className='btn btn-danger flex items-center gap-x-3'
                            data-cy='global-admission-view-delete'
                            onClick={() => {
                              setDeleteStudentView(studentView)
                              setConfirmAlertOpen(true)
                            }}
                          >
                            Delete <FaTrashAlt size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {Boolean(studentViews?.data?.length ?? 0 > 0) && (
          <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-primary [&>*_a:hover]:text-white [&>*_li.active>a]:bg-primary [&>*_li.active>a]:text-white'>
            <ReactJsPagination
              activePage={studentViews?.currentPage || 1}
              itemsCountPerPage={studentViews?.perPage}
              totalItemsCount={studentViews?.total || 0}
              pageRangeDisplayed={studentViews?.to}
              onChange={(pageNumber) => {
                getStudentViewData(pageNumber)
              }}
            />
          </div>
        )}

        <div className='flex md:flex-col justify-between items-center md:items-start gap-4 mt-3 md:mt-6'>
          <h1 className='text-3xl font-bold decoration-1 md:w-3/5'>Global Visa Student View</h1>
          <button
            className='btn btn-primary flex items-center gap-x-3 ml-auto hover:!bg-[var(--local-theme-color)]'
            data-cy='global-visa-view-create'
            onClick={() => createNewView(true)}
          >
            Create Global Visa View <CiSquarePlus className='w-6 h-6' />
          </button>
        </div>
        <div className='overflow-auto'>
          <table className='w-full mt-5 lg:w-max' id='global-visa-views-table' data-cy='global-visa-views-table'>
            <thead>
              <tr className='[&>*]:bg-[var(--local-theme-color)] [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                <th className='w-[5%]'>#</th>
                <th className='w-[30%]'>View Title</th>
                <th className='w-[35%]'>Assigned to role</th>
                <th className=''>Action</th>
              </tr>
            </thead>
            <tbody>
              {visaStudentViews?.data.length === 0 || !visaStudentViews?.data ? (
                <>
                  <tr>
                    <td colSpan={3}>No Global Visa Student View Found</td>
                  </tr>
                </>
              ) : (
                <>
                  {visaStudentViews?.data.map((studentView, index) => (
                    <tr key={studentView.id} data-id={studentView.id}>
                      <td>{(visaStudentViews.currentPage - 1) * visaStudentViews.perPage + index + 1}</td>
                      <td>
                        {editView && editView === studentView.id ? (
                          <>
                            <input
                              type='text'
                              className={`form-input mt-2 min-h-14 max-w-[95%] ${editErrors?.title ? 'border border-red-500' : ''}`}
                              data-cy='global-visa-view-title'
                              defaultValue={studentView?.title}
                              placeholder={`Enter View Title`}
                              onChange={(e) => {
                                setViewName(e.target.value)
                              }}
                              name={'title'}
                            />
                            {editErrors?.title && <span className='text-sm text-red-500'>{editErrors.title}</span>}
                          </>
                        ) : (
                          <>{studentView?.title}</>
                        )}
                      </td>
                      <td>
                        {editView && editView === studentView.id ? (
                          <div className='flex flex-col'>
                            <FormControl sx={{ m: 1, minWidth: '160px' }}>
                              <InputLabel id='student-views-roles-label'>Roles</InputLabel>
                              <Select
                                className='min-w-[340px] max-w-[340px] role-dropdown-tag-container-parent'
                                data-cy='global-visa-view-roles'
                                error={Boolean(editErrors?.roles)}
                                labelId='student-views-roles-label'
                                id='student-views-roles'
                                multiple
                                autoWidth
                                value={viewRoles}
                                onChange={(event) => {
                                  const { value } = event.target
                                  setViewRoles(value as number[])
                                }}
                                input={<OutlinedInput label='Roles' />}
                                renderValue={(selected) => {
                                  const selectedNames = roles
                                    ?.filter((role) => selected.includes(role.id))
                                    .map((role) => toTitleCase(role.name, '_'))
                                  return (
                                    <>
                                      {selectedNames
                                        ? selectedNames.map((rName) => (
                                            <span
                                              key={rName}
                                              className='px-3 py-2 text-sm rounded-md bg-[var(--local-theme-color)] text-white'
                                            >
                                              {rName}
                                            </span>
                                          ))
                                        : ''}
                                    </>
                                  )
                                }}
                              >
                                {roles?.map((role) => {
                                  return (
                                    <MenuItem key={`role_${role.id}`} value={role.id}>
                                      <Checkbox checked={viewRoles?.indexOf(role?.id) > -1} />
                                      <ListItemText primary={toTitleCase(role.name, '_')} />
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                            {editErrors?.roles && <span className='ml-2 text-sm text-red-500'>{editErrors.roles}</span>}
                          </div>
                        ) : (
                          <div className='flex flex-wrap gap-y-2 gap-x-3 max-w-[340px]'>
                            {studentView.roles.map((rName) => (
                              <span
                                key={`${studentView.id}_${rName.name}`}
                                className='px-3 py-2 text-sm rounded-md bg-[var(--local-theme-color)] text-white'
                              >
                                {toTitleCase(rName.name, '_')}
                              </span>
                            ))}
                          </div>
                        )}
                      </td>
                      <td>
                        <div className='flex gap-5 items-center'>
                          {editView && editView === studentView.id ? (
                            <div className='flex gap-2 mr-4'>
                              <button
                                className='btn btn-primary flex items-center gap-x-3'
                                data-cy='global-visa-view-save'
                                onClick={() => handleChange(studentView)}
                              >
                                Save <FaPencilAlt size={14} />
                              </button>
                              <button
                                className='btn btn-secondary flex items-center gap-x-3'
                                data-cy='global-visa-view-cancel'
                                onClick={resetEdit}
                              >
                                Cancel <FaX size={14} />
                              </button>
                            </div>
                          ) : (
                            <button
                              className='btn btn-primary flex items-center gap-x-3'
                              data-cy='global-visa-view-edit'
                              onClick={() => {
                                setEditErrors({})
                                setEditView(studentView.id)
                                setViewName(studentView.title)
                                setViewRoles(studentView.roles.map((r) => r.id))
                              }}
                            >
                              Edit <FaPencilAlt size={14} />
                            </button>
                          )}
                          <button
                            className='btn btn-danger flex items-center gap-x-3'
                            data-cy='global-visa-view-delete'
                            onClick={() => {
                              setDeleteStudentView(studentView)
                              setConfirmAlertOpen(true)
                            }}
                          >
                            Delete <FaTrashAlt size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {Boolean(visaStudentViews?.data?.length ?? 0 > 0) && (
          <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-[var(--local-theme-color)] [&>*_a:hover]:text-white [&>*_li.active>a]:bg-[var(--local-theme-color)] [&>*_li.active>a]:text-white'>
            <ReactJsPagination
              activePage={visaStudentViews?.currentPage || 1}
              itemsCountPerPage={visaStudentViews?.perPage}
              totalItemsCount={visaStudentViews?.total || 0}
              pageRangeDisplayed={visaStudentViews?.to}
              onChange={(pageNumber) => {
                getStudentViewData(pageNumber, true)
              }}
            />
          </div>
        )}
        <ConfirmationAlert
          title='Are you sure you perform this action ?'
          isConfirmModalOpen={isConfirmAlertOpen}
          setConfirmModalOpen={(val: boolean) => {
            setConfirmAlertOpen(val)
            if (!val) setDeleteStudentView(null)
          }}
          ButtonText={'Yes I am sure'}
          actionName={
            <>
              Delete global student view
              <div>This will also delete this view for all the users.</div>
            </>
          }
          onAction={handleDeleteStudentView}
        />
      </div>
    </>
  )
}
