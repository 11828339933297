import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material'
import React, { useState, useContext, useEffect, useRef } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import { studentContext } from '../context'
import { useSearchParams } from 'react-router-dom'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'

interface Props {
  setOPenFilter: React.Dispatch<React.SetStateAction<boolean>>
  submit: (arg: any) => void
  show?: boolean
}

export default function Filter({ setOPenFilter, submit, show }: Props) {
  const [ismenuExpand, setMenuExpand] = useState<number[]>([])
  const [refresh, setRefresh] = useState(true)
  const { state } = useContext(studentContext)
  const [multipleValue, setMultipleValue] = useState<Record<string, unknown>>({})
  const inputRef = useRef<HTMLInputElement>(null)
  interface StatusFilter {
    value: string
    label: string
  }
  const [statusFilter] = useState<StatusFilter[]>([
    { value: 'new', label: 'New Application' },
    { value: 'accepted', label: 'Accepted Application' },
    { value: 'conditionally_accepted', label: 'Conditionally Accepted Application' },
    { value: 'committed', label: 'Committed Application' },
    { value: 'completed', label: 'Completed Application' },
    { value: 'withdrawn', label: 'Withdrawn Application' }
  ])

  const [visaStatusFilter] = useState<StatusFilter[]>([
    { value: 'New', label: 'New Application' },
    { value: 'Processing', label: 'Processing' },
    { value: 'Completed', label: 'Completed' }
  ])

  const [defaultStatusFilter, setDefaultStatusFilter] = useState<StatusFilter[]>([])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const init = async () => {
      const applicationStatus = searchParams.get('application_status')
      const sendingInstitution = searchParams.get('sendingInstitution')
      const instituteId = searchParams.get('instituteId')
      const programId = searchParams.get('programId')
      const sessionId = searchParams.get('sessionId')
      const yearId = searchParams.get('yearId')
      const homeCollegeId = searchParams.get('homeCollegeId')
      const depositDueDate = searchParams.get('due_date')
      const depositPaidDate = searchParams.get('deposit_paid_date')
      const balanceDueDate = searchParams.get('invoice_balance_due_date')
      const balancePaidDate = searchParams.get('invoice_balance_paid_date')
      if (applicationStatus) {
        const defaultVal = statusFilter.find((item) => item.value === applicationStatus)
        setDefaultStatusFilter([defaultVal as StatusFilter])
        const defaultValueArray = statusFilter.filter((item) => item.value === applicationStatus).map((item) => item.value)
        setMultipleValue((prevState) => ({
          ...prevState,
          application_status: defaultValueArray
        }))
      }
      if (instituteId) {
        const defaultVal = instituteId
        setMultipleValue((prevState) => ({
          ...prevState,
          host_institute: [Number(defaultVal)]
        }))
      }
      if (programId) {
        const defaultVal = programId
        setMultipleValue((prevState) => ({
          ...prevState,
          program: [Number(defaultVal)]
        }))
      }
      if (sessionId) {
        const defaultVal = sessionId
        setMultipleValue((prevState) => ({
          ...prevState,
          session: [Number(defaultVal)]
        }))
      }
      if (yearId) {
        const defaultVal = yearId
        setMultipleValue((prevState) => ({
          ...prevState,
          year: [Number(defaultVal)]
        }))
      }
      if (homeCollegeId) {
        const defaultVal = homeCollegeId
        setMultipleValue((prevState) => ({
          ...prevState,
          home_college_university: [Number(defaultVal)]
        }))
      }
      if (sendingInstitution) {
        const defaultVal = sendingInstitution
        setMultipleValue((prevState) => ({
          ...prevState,
          sending_institution: [defaultVal]
        }))
      }
      if (depositDueDate) {
        const defaultVal = depositDueDate
        setMultipleValue((prevState) => ({
          ...prevState,
          due_date: defaultVal
        }))
      }
      if (depositPaidDate) {
        const defaultVal = depositPaidDate
        setMultipleValue((prevState) => ({
          ...prevState,
          deposit_paid_date: defaultVal
        }))
      }
      if (balanceDueDate) {
        const defaultVal = balanceDueDate
        setMultipleValue((prevState) => ({
          ...prevState,
          invoice_balance_due_date: defaultVal
        }))
      }
      if (balancePaidDate) {
        const defaultVal = balancePaidDate
        setMultipleValue((prevState) => ({
          ...prevState,
          invoice_balance_paid_date: defaultVal
        }))
      }
    }
    init()
  }, [])

  const handleSelectChange = async (value: any, itemId: string) => {
    await setMultipleValue((prevState) => ({
      ...prevState,
      [itemId]: value
    }))
    if ((typeof value == 'string' || Array.isArray(value)) && value.length == 0) {
      setMultipleValue((prevState) => {
        const newState = { ...prevState }
        delete newState[itemId]
        return newState
      })
    }
    if (itemId == 'application_status') {
      const defaultVals = value.map((val: any) => statusFilter.find((item) => item.value == val)).filter(Boolean)
      setDefaultStatusFilter(defaultVals)
      if (value.length == 0) {
        setDefaultStatusFilter([])
      }
    }
  }

  async function expandMenu(index: number) {
    if (ismenuExpand?.includes(index)) {
      const temp_arr = await ismenuExpand?.filter((i) => i !== index)
      await setMenuExpand(temp_arr)
    } else {
      const temp = ismenuExpand
      temp.push(index)
      await setMenuExpand(temp)
    }
    await setRefresh(!refresh)
  }

  const handelSubmit = () => {
    if (state?.filters?.length === 0) {
      return
    }
    submit(multipleValue)
  }

  const getDefaultValue = (item: any): string | Array<any> | undefined => {
    if (item.fieldName === 'host_institute' && multipleValue?.host_institute) {
      const val = item?.choices?.filter((choice: any) => {
        return (multipleValue?.host_institute as Array<string>)?.includes(choice.id)
      })
      return val
    }
    if (item.fieldName === 'program' && multipleValue?.program) {
      const val = item?.choices?.filter((choice: any) => {
        return (multipleValue?.program as Array<string>)?.includes(choice.id)
      })
      return val
    }
    if (item.fieldName === 'session' && multipleValue?.session) {
      const val = item?.choices?.filter((choice: any) => {
        return (multipleValue?.session as Array<string>)?.includes(choice.id)
      })
      return val
    }
    if (item.fieldName === 'year' && multipleValue?.year) {
      const val = item?.choices?.filter((choice: any) => {
        return (multipleValue?.year as Array<string>)?.includes(choice.id)
      })
      return val
    }
    if (item.fieldName === 'home_college_university' && multipleValue?.home_college_university) {
      const val = item?.choices?.filter((choice: any) => {
        return (multipleValue?.home_college_university as Array<string>)?.includes(choice.id)
      })
      return val
    }
    if (item.fieldName === 'sending_institution' && multipleValue?.sending_institution) {
      const val = item?.choices?.filter((choice: any) => {
        return (multipleValue?.sending_institution as Array<string>)?.includes(choice.id)
      })
      return val
    }

    return []
  }

  const handleFilterClick = async () => {
    if (state?.filters?.length === 0) {
      return
    }

    await setDefaultStatusFilter([])
    await setMultipleValue({})
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    handleSelectChange([], 'application_status')
    setOPenFilter(false)
    setMenuExpand([])
    submit({})
  }

  return (
    <div
      className={`fixed px-3 py-5 border w-1/5 lg:w-[35%] h-[calc(100vh-77px)] md:w-9/12 left-0 bg-white z-10 md:h-[calc(100vh-48px)] transition-all duration-300 ease-in-out ${show ? '' : '-translate-x-full'}`}
    >
      <div className='flex justify-between items-center pb-4'>
        <h1 className='font-bold'>Filters</h1>
        <div className='flex gap-6 items-center'>
          <div
            className={`font-semibold text-xs text-cyan-800 cursor-pointer ${state?.filters?.length === 0 ? 'disabled' : ''}`}
            onClick={handleFilterClick}
          >
            Clear Filters
          </div>
          <MdClose
            size={'20px'}
            className='font-semibold text-xl text-cyan-800 cursor-pointer flex gap-1'
            onClick={() => {
              setOPenFilter(false)
            }}
          />
        </div>
      </div>
      <div className='overflow-auto h-[calc(100vh-210px)] md:pr-2'>
        {state?.filters?.length === 0 ? (
          <p>No filters enabled</p>
        ) : (
          state?.filters
            ?.sort(function (a, b) {
              return a.filterColumnOrder - b.filterColumnOrder
            })
            ?.filter((item) => item.fieldName !== 'profile_image')
            ?.map((item, index) => {
              return (
                <div key={`filter_list_wrapper_${index}_${item.fieldName}`}>
                  {['application_status', 'visa_portal_status'].includes(item.fieldName) ? (
                    <div className='py-3 border-y'>
                      <div className='flex justify-between items-center cursor-pointer' onClick={() => expandMenu(999)}>
                        <h1 className='text-base text-gray-600'>{item?.label}</h1>
                        <MdAdd color='#4b5563' size={'14px'} />
                      </div>
                      {ismenuExpand.includes(999) && (
                        <div className='flex justify-center mt-2'>
                          <>
                            <Autocomplete
                              className='w-full'
                              sx={{ m: 1, minWidth: 120 }}
                              multiple
                              size='small'
                              options={item.fieldName === 'visa_portal_status' ? visaStatusFilter : statusFilter}
                              onChange={(event, selectedOptions) => {
                                const selectedIds = selectedOptions?.map((option) => option.value)
                                handleSelectChange(
                                  selectedIds,
                                  item.fieldName === 'visa_portal_status' ? 'visa_portal_status' : 'application_status'
                                )
                              }}
                              getOptionLabel={(statusFilter) => statusFilter.label || ''}
                              renderInput={(renderItem) => <TextField {...renderItem} label={item?.label} />}
                              value={item.fieldName === 'visa_portal_status' ? undefined : defaultStatusFilter}
                            />
                          </>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div key={`filter_list_${index}_${item.fieldName}`} className='py-3 border-y'>
                      <div className='flex justify-between items-center cursor-pointer' onClick={() => expandMenu(index)}>
                        <h1 className='text-base text-gray-600'>{item?.label}</h1>
                        <MdAdd color='#4b5563' size={'14px'} />
                      </div>
                      {ismenuExpand.includes(index) && (
                        <div className='flex mt-2'>
                          {item.type === 'date' ? (
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={multipleValue[item.fieldName] === 'hasValue'}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked
                                      handleSelectChange(isChecked ? 'hasValue' : null, item.fieldName)
                                    }}
                                  />
                                }
                                label={`Has ${item.label.toLowerCase()}`}
                              />

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                  <DatePicker
                                    value={
                                      multipleValue[item.fieldName] && multipleValue[item.fieldName] !== 'hasValue'
                                        ? dayjs(String(multipleValue[item.fieldName]))
                                        : null
                                    }
                                    label='Select date'
                                    onChange={(newValue: dayjs.Dayjs | null) => {
                                      handleSelectChange(newValue?.format('YYYY-MM-DD'), item.fieldName)
                                    }}
                                    defaultValue={null}
                                    slotProps={{
                                      actionBar: { actions: ['clear'] }
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          ) : item.choices ? (
                            <>
                              <Autocomplete
                                className='w-full'
                                sx={{ m: 1, minWidth: 120 }}
                                multiple
                                size='small'
                                disabled={item.choices.length === 0}
                                options={item.choices || []}
                                onChange={(event, selectedOptions) => {
                                  const selectedIds = selectedOptions.map((option) => option.id)
                                  handleSelectChange(selectedIds, item.fieldName)
                                }}
                                getOptionLabel={(option) => option.name || ''}
                                renderInput={(params) => <TextField {...params} label={item.label} />}
                                defaultValue={getDefaultValue(item) as any}
                              />
                            </>
                          ) : (
                            <input
                              className='form-input w-70 mt-2'
                              type='text'
                              placeholder={item.label}
                              ref={inputRef}
                              onBlur={(event) => handleSelectChange(event?.target?.value ?? '', item.fieldName)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            })
        )}
      </div>
      <div className='flex absolute bottom-0 p-3 right-0 left-0 justify-center border-t bg-white'>
        <button className={`btn-secondary w-full ${state?.filters?.length === 0 ? 'disabled' : ''}`} onClick={handelSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}
