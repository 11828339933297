import { Close } from '@mui/icons-material'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

type BaseVariant = 'default' | 'error' | 'success' | 'warning' | 'info'

export const toast = (message: any, variant: BaseVariant, options?: object) => {
  const uniqueKey = Math.random().toString(30)
  const toastMessage = typeof message == 'string' ? message : 'Something went wrong'

  enqueueSnackbar(toastMessage, {
    key: uniqueKey,
    variant: variant,
    ...options,
    action: () => (
      <div className='cursor-pointer' onClick={() => closeSnackbar(uniqueKey)}>
        <Close />
      </div>
    )
  })
}
