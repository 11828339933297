import { MdOutlineFilterAlt, MdSearch, MdViewColumn } from 'react-icons/md'
import Checkbox from '@mui/material/Checkbox'
import React, { useContext, useEffect, useState } from 'react'
import Filter from './Filter'
import ColumnFilter from './ColumnFilter'
import { getFieldList, bulkAssignAdvisor, getAllStudent, getAllAdvisor } from '../api'
import { studentContext } from '../context'
import { ActionType, AdvisorType, Fields, SubStepIcons } from '../types'
import { Select, MenuItem, InputLabel, FormControl, FormGroup, FormControlLabel } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { FaSort, FaEye, FaSync } from 'react-icons/fa'
import ConfirmationModal from 'Components/ConfirmationModal'
import ReactJsPagination from 'react-js-pagination'
import Loading from 'Components/Loading'
import ImageSelector from 'Components/ImageSelector'
import { Link, useSearchParams } from 'react-router-dom'
import { PerPage } from 'Components/PerPage'
import { toast } from 'Components/Toast/toast'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import http from 'CommonJS/http'
import noImagePlaceholder from 'assets/images/no-image-placeholder.png'
import FileUpload from 'Components/FileUpload'
import Export from '../export'
import { UserContext } from 'Context/UserContext'
import { AppStatusStyle, StatusEnum } from 'CommonJS/StatusEnum'
import { TbFilterEdit } from 'react-icons/tb'
import { isAdmin, isManagerAdvisor } from 'CommonJS/roleHelper'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { debounce, formatDate, isValidDate } from 'CommonJS/common'
import { FreezeTableColumns } from 'Components/FreezeTableColumns'
import EventBus from 'CommonJS/eventBus'
import View from './View'
import { userCan } from 'CommonJS/PermissionHelper'
import { PermissionEnum } from 'CommonJS/PermissionEnum'
declare global {
  interface Window {
    distance?: number
  }
}

export default function VisaPortalStudent() {
  const [openFilter, setOPenFilter] = useState(false)
  const [openView, setOpenView] = useState(false)
  const [openColumnFilter, setOpenColumnFilter] = useState(false)
  const [openToggleFilter, setOpenToggleFilter] = useState(false)
  const { state, dispatch } = useContext(studentContext)
  const { students, fields, column } = state
  const [advisorList, setAdvisorList] = useState<AdvisorType[]>()
  const [selectedStudentId, setSelectedStudent] = useState<number[]>([])
  const [selectedAdvisor, setSelectedAdvisor] = useState<number | null>(null)
  const ids = Object.values(students.data).map((item) => item.id)
  const [sortOrder, setSortOrder] = useState({ columnId: null, ascending: true })
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [multipleValue, setMultipleValue] = useState<Record<string, any>>({})
  const [itemsPerPage, setItemsPerPage] = useState<number>(50)
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState<boolean>(false)
  const [action, setAction] = useState<string>('')
  const [actionLabel, setActionLabel] = useState<string>('')
  const { data } = useContext(UserContext)
  const [autoScrollSpeed, setAutoScrollSpeed] = useState<number>(50)
  const [autoScrollSpeedLock, setAutoScrollSpeedLock] = useState<boolean>(true)
  const [inputSearch, setInputSearch] = useState<string>('')
  const [subStepStatusIcons, setSubStepStatusIcons] = useState<SubStepIcons>({})
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const IMG_BASE_URL = process.env.REACT_APP_S3_BASE_URL ?? ''
  const GF_SIGN_BASE_URL = '?page=gf_signature'

  async function getFields(fieldOnly: boolean = false) {
    const res = await getFieldList({ query: { fieldOnly } })
    if (res) {
      if (fieldOnly === true) {
        dispatch({ type: ActionType.SET_FIELDS, payload: res.fields })
      } else {
        setIsFirstRender(true)
        const sortedFields = res.fields.sort((a: Fields, b: Fields) => a.filterColumnOrder - b.filterColumnOrder)
        dispatch({ type: ActionType.SET_FIELDS, payload: sortedFields })
        dispatch({ type: ActionType.SET_FILTERS, payload: res.filters })
        dispatch({ type: ActionType.SET_COLUMN, payload: res.fields.filter((item: any) => item.isDefault).map((item: any) => item.id) })
      }
    }
  }

  async function advisors() {
    const res = await getAllAdvisor()
    if (res) {
      setAdvisorList(res.advisors)
    }
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const [hadSearchFilter, setHadSearchFilter] = useState<boolean>(false)
  useEffect(() => {
    const applicationStatus = searchParams.get('visa_portal_status')
    const sendingInstitution = searchParams.get('sendingInstitution')
    const instituteId = searchParams.get('instituteId')
    const programId = searchParams.get('programId')
    const sessionId = searchParams.get('sessionId')
    const yearId = searchParams.get('yearId')
    const homeCollegeId = searchParams.get('homeCollegeId')
    const depositDueDate = searchParams.get('due_date')
    const depositPaidDate = searchParams.get('deposit_paid_date')
    const balanceDueDate = searchParams.get('invoice_balance_due_date')
    const balancePaidDate = searchParams.get('invoice_balance_paid_date')
    const init = async () => {
      await setLoading(true)
      if (applicationStatus) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          visa_portal_status: [applicationStatus]
        }))
      }
      if (sendingInstitution) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          sending_institution: [sendingInstitution]
        }))
      }
      if (instituteId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          host_institute: [instituteId]
        }))
      }
      if (programId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          program: [programId]
        }))
      }
      if (sessionId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          session: [sessionId]
        }))
      }
      if (yearId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          year: [yearId]
        }))
      }
      if (homeCollegeId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          home_college_university: [homeCollegeId]
        }))
      }
      if (depositDueDate) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          due_date: depositDueDate
        }))
      }
      if (depositPaidDate) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          deposit_paid_date: depositPaidDate
        }))
      }
      if (balanceDueDate) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          invoice_balance_due_date: balanceDueDate
        }))
      }
      if (balancePaidDate) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          invoice_balance_paid_date: balancePaidDate
        }))
      }
      await Promise.all([getFields(), advisors()])

      const storedSpeed = localStorage.getItem('autoScrollSpeed')
      window.distance = Number(autoScrollSpeed)
      if (storedSpeed) {
        setAutoScrollSpeed(Number(storedSpeed))
        window.distance = Number(storedSpeed)
      }
      setAutoScrollSpeedLock(false)
      document.addEventListener('keydown', addArrowScrollEvents)
    }
    init()

    return () => {
      document.removeEventListener('keydown', addArrowScrollEvents)
    }
  }, [])

  useEffect(() => {
    const getData = async () => {
      await setLoading(true)
      await Promise.all([getStudents()])
      await setLoading(false)
    }
    isFirstRender
    if (column?.length > 0 || isFirstRender) {
      getData()
    }
  }, [column, sortOrder, multipleValue])

  useEffect(() => {
    if (!hadSearchFilter) return

    let newApplicationStatus = multipleValue?.visa_portal_status?.toString()
    if (multipleValue?.visa_portal_status && 'value' in (multipleValue?.visa_portal_status as object)) {
      newApplicationStatus = (multipleValue?.visa_portal_status as any)?.map((s: { value: any }) => s.value)
    }

    setSearchParams((prevSearchParams) => {
      const updatedSearchParams = new URLSearchParams(prevSearchParams) // Create a new URLSearchParams object

      if (newApplicationStatus) {
        updatedSearchParams.set('visa_portal_status', newApplicationStatus)
      } else {
        updatedSearchParams.delete('visa_portal_status')
      }
      const fields = {
        host_institute: 'instituteId',
        session: 'sessionId',
        program: 'programId',
        year: 'yearId',
        home_college_university: 'homeCollegeId',
        sending_institution: 'sendingInstitution',
        due_date: 'dueDate',
        deposit_paid_date: 'depositPaidDate',
        invoice_balance_due_date: 'invoiceBalanceDueDate',
        invoice_balance_paid_date: 'invoiceBalancePaidDate'
      }

      Object.entries(fields).forEach(([field, paramName]) => {
        if (!multipleValue?.[field] || multipleValue?.[field]?.length === 0) {
          updatedSearchParams.delete(field)
          updatedSearchParams.delete(paramName)
        }
      })

      return updatedSearchParams.toString() // Return a string representation for URL update
    })
  }, [
    multipleValue?.visa_portal_status,
    multipleValue?.sending_institution,
    multipleValue?.host_institute,
    multipleValue?.session,
    multipleValue?.year,
    multipleValue?.home_college_university,
    multipleValue?.program,
    multipleValue?.due_date,
    multipleValue?.deposit_paid_date,
    multipleValue?.invoice_balance_due_date,
    multipleValue?.invoice_balance_paid_date
  ])

  const loadIcons = async () => {
    return http
      .fetch({ method: 'get', path: 'setting/icons' })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
      .then((response) => {
        if (response && response.status === 200) {
          setSubStepStatusIcons(response.data.setting)
        }
      })
  }

  async function getStudents(pageNumber: number = 1, perPage?: number) {
    try {
      let items = itemsPerPage
      if (perPage || perPage == 0) {
        items = perPage
      }
      setCurrentPage(pageNumber)
      setLoading(true)
      const res = await getAllStudent({ pageNumber, fields: column, filterData: multipleValue, sortOrder, perPage: items })
      if (res) {
        dispatch({ type: ActionType.SET_STUDENTS, payload: [] })
        dispatch({ type: ActionType.SET_STUDENTS, payload: res.students })

        if (Object.keys(subStepStatusIcons).length === 0) {
          const hasCustomFields = fields.some((field) => {
            return field.isCustom && (field.isDefault || column.includes(field.fieldName))
          })

          if (hasCustomFields) {
            await loadIcons()
          }
        }
        setLoading(false)
      } else {
        setLoading(false)
        toast('An error occurred', 'error')
      }
      EventBus.$dispatch('get:student-listing', '')
    } catch (error) {
      setLoading(false)
      toast('An error occurred', 'error')
    }
  }
  const handleFilter = (filter: any) => {
    setMultipleValue(() => {
      const udpatedFilter = {
        onlyTrashed: multipleValue?.onlyTrashed,
        advisorId: multipleValue?.advisorId,
        searchParam: multipleValue?.searchParam,
        ...filter
      }
      return udpatedFilter
    })
  }

  useEffect(() => {
    if (autoScrollSpeedLock) return
    localStorage.setItem('autoScrollSpeed', String(autoScrollSpeed))
    window.distance = autoScrollSpeed
  }, [autoScrollSpeed])

  const addArrowScrollEvents = (e: KeyboardEvent) => {
    const elm = document.getElementById('student-data')

    if (e.ctrlKey === true) {
      if (e.code == 'BracketRight') {
        debounce(() => {
          setAutoScrollSpeed((prev) => {
            return prev < 300 ? prev + 10 : prev
          })
        }, 200)
        return
      }
      if (e.code == 'BracketLeft') {
        debounce(() => {
          setAutoScrollSpeed((prev) => {
            return prev > 10 ? prev - 10 : prev
          })
        }, 200)
        return
      }
    }

    if (e.key == 'ArrowRight' && elm) {
      elm.scrollLeft += Number(window?.distance)
    }
    if (e.key == 'ArrowLeft' && elm) {
      elm.scrollLeft -= Number(window?.distance)
    }
  }

  const selectDeselectAll = (action: boolean) => {
    action ? setSelectedStudent([...ids]) : setSelectedStudent([])
  }

  const handleCheckboxChange = (studentId: number) => {
    setSelectedStudent((prevState) => {
      if (prevState.includes(studentId)) {
        return prevState.filter((id) => id !== studentId)
      } else {
        return [...prevState, studentId]
      }
    })
  }

  const changeAdvisorInBulk = async (data: any) => {
    if (selectedStudentId.length == 0) {
      toast('Please select students', 'error')
      return false
    }
    setLoading(true)
    const payload = {
      studentId: selectedStudentId,
      advisor_id: data.advisor_assign
    }
    try {
      const advisor = await bulkAssignAdvisor(payload)
      if (advisor.success) {
        setConfirmModalOpen(false)
        setLoading(false)
        toast(advisor?.message, 'success')
        getStudents()
      } else {
        setLoading(false)
        toast('An error occurred', 'error')
      }
    } catch (error) {
      setLoading(false)
      toast('An error occurred', 'error')
    }
  }

  const shortByColumn = (columnId: any) => {
    if (sortOrder.columnId === columnId) {
      setSortOrder((prevState) => ({
        columnId,
        ascending: !prevState.ascending
      }))
    } else {
      setSortOrder({ columnId, ascending: true })
    }
  }

  const performAction = () => {
    if (selectedStudentId.length == 0) {
      toast('Please select students', 'error')
      return false
    }
    const payload = {
      studentId: selectedStudentId
    }
    http
      .fetch({ method: 'put', path: `students/${null}/${action}`, data: payload })
      .catch(() => {
        toast('Internal server error.', 'error')
        setConfirmAlertOpen(false)
      })
      .then((response) => {
        if (response && response.status === 200) {
          toast(response?.data.message, 'success')
          getStudents()
        }
        setConfirmAlertOpen(false)
      })
  }

  const exportTo = (format: string) => {
    return getAllStudent({ fields: column, filterData: multipleValue, sortOrder, params: { download: format } })
  }

  return (
    <div className='w-full lg:px-4 flex student-listing' data-cy='visa-students-page'>
      {loading && <Loading />}
      {userCan(data, PermissionEnum.STUDENT_LIST_SHOW_FILTERS) && (
        <Filter show={openFilter} setOPenFilter={setOPenFilter} submit={handleFilter} />
      )}
      <View show={openView} setOpenView={setOpenView} />
      <div
        className={`px-5 py-5 overflow-auto md:px-0 ml-auto transition-all duration-300 lg:px-0 ${openFilter ? 'w-[calc(100%-20%)] lg:w-[calc(100%-0%)]' : 'w-full'}`}
      >
        <div className='flex gap-4 justify-end'>
          <button
            className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
            data-cy='student-view-button'
            onClick={() => {
              setOpenView(!openView)
            }}
          >
            Views
          </button>
        </div>
        <div className='flex justify-between items-center mt-5 lg:flex-wrap md:mt-0'>
          <div className='flex gap-4 items-center'>
            <h1 className='text-3xl font-bold lg:mb-5'>Visa Students</h1>
            <Tooltip
              title='Use Keyboard ArrowRight/ArrowLeft keys to scroll students listing horizontally. Use `Ctrl + ]` to increase or `Ctrl + [` to decrease scroll speed with arrow key.'
              classes={{ popper: '[&_div]:text-base' }}
              enterTouchDelay={1}
              placement='bottom'
              arrow
            >
              <span>
                <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
              </span>
            </Tooltip>
            <div className='text-sm'>Auto scroll speed: {autoScrollSpeed}</div>
          </div>
          <div className='flex gap-4 lg:min-w-full lg:justify-end lg:flex-wrap md:gap-2 md:justify-end'>
            <div className='flex gap-4 items-center'>
              <FormGroup>
                <input
                  data-cy='search-input'
                  className='form-input'
                  id='grid-first-name'
                  type='text'
                  value={inputSearch}
                  placeholder='Search'
                  onChange={(e) => setInputSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== 'Enter') return

                    setMultipleValue((prevState) => {
                      return {
                        ...prevState,
                        searchParam: inputSearch
                      }
                    })
                  }}
                />
              </FormGroup>
              <button
                data-cy='search-button'
                className='flex items-center gap-2 btn btn-primary px-4'
                onClick={() => {
                  setMultipleValue((prevState) => {
                    return {
                      ...prevState,
                      searchParam: inputSearch
                    }
                  })
                }}
              >
                <MdSearch size={'30px'} title='Search' />
              </button>
            </div>
            {userCan(data, PermissionEnum.STUDENT_LIST_SHOW_FILTERS) && (
              <button
                className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
                onClick={() => setOPenFilter(!openFilter)}
                data-cy='show-filters'
              >
                <MdOutlineFilterAlt size={'30px'} className='cursor-pointer' title='Filters' />
                Show Filters
              </button>
            )}
            {userCan(data, PermissionEnum.STUDENT_LIST_CHANGE_FILTER_FIELDS) && (
              <button
                data-cy='change-filter-fields'
                className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
                onClick={() => {
                  setOpenToggleFilter(!openToggleFilter)
                  setOpenColumnFilter(false)
                }}
              >
                <TbFilterEdit size={'30px'} className='cursor-pointer' title='Toggle Filters' />
                Change Filters Fields
              </button>
            )}
            {userCan(data, PermissionEnum.STUDENT_LIST_CHANGE_DISPLAY_COLUMNS) && (
              <button
                data-cy='change-display-columns'
                className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
                onClick={() => {
                  setOpenColumnFilter(!openColumnFilter)
                  setOpenToggleFilter(false)
                }}
              >
                <MdViewColumn size={'30px'} className='cursor-pointer' title='Toggle Fields' />
                Change Display Columns
              </button>
            )}
          </div>
        </div>
        <div className='flex justify-between items-center mt-5 lg:flex-wrap lg:flex-col-reverse lg:items-start'>
          <div className='flex gap-3'>
            <PerPage
              onChange={(value) => {
                setItemsPerPage(value)
                getStudents(1, value)
              }}
            />
            <FreezeTableColumns containerSelector='.student-listing' />
          </div>

          <div className='flex gap-x-4 items-center lg:min-w-full lg:justify-end lg:flex-wrap md:relative md:flex-col md:items-end md:pb-11'>
            {isManagerAdvisor(data.roles) && userCan(data, PermissionEnum.STUDENT_LIST_YOUR_STUDENTS) && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleFilter({ ...multipleValue, advisorId: !multipleValue.advisorId })}
                      checked={Boolean(multipleValue?.advisorId || false)}
                      value={'advisorId'}
                    />
                  }
                  label='Your Students'
                />
              </FormGroup>
            )}
            {userCan(data, PermissionEnum.STUDENT_LIST_SHOW_ARCHIVED_STUDENTS) && (
              <FormGroup className='md:absolute md:bottom-0'>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy='show-archived-students'
                      onChange={() => handleFilter({ ...multipleValue, onlyTrashed: !multipleValue.onlyTrashed })}
                      checked={Boolean(multipleValue?.onlyTrashed || false)}
                      value={'onlyTrashed'}
                    />
                  }
                  label='Show Archived Students'
                />
              </FormGroup>
            )}
            {isAdmin(data.roles) && (
              <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-44'>
                <InputLabel id='student-list-bulk-action-select-label'>Bulk Action </InputLabel>
                <Select
                  data-cy='bulk-action-select'
                  id='student-list-bulk-action-select'
                  labelId='student-list-bulk-action-select-label'
                  label='Bulk Action'
                  className=''
                  value={''}
                >
                  {multipleValue?.onlyTrashed ? (
                    <MenuItem
                      value={'unArchiveApplication'}
                      onClick={() => {
                        setConfirmAlertOpen(true)
                        setAction('unArchiveApplication')
                        setActionLabel('Unarchive Application')
                      }}
                    >
                      UnArchive Application
                    </MenuItem>
                  ) : (
                    <div>
                      <MenuItem
                        value={'assign_advisor'}
                        onClick={() => {
                          setConfirmModalOpen(true)
                        }}
                      >
                        Assign Advisor
                      </MenuItem>
                      <MenuItem
                        value={'withdrawApplication'}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction('withdrawApplication')
                          setActionLabel('Withdraw Application')
                        }}
                      >
                        Withdraw Application
                      </MenuItem>
                      <MenuItem
                        value={'archiveApplication'}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction('archiveApplication')
                          setActionLabel('Archive Application')
                        }}
                      >
                        Archive Application
                      </MenuItem>

                      <MenuItem
                        value={'deleteApplication'}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction('deleteApplication')
                          setActionLabel('Delete Application')
                        }}
                      >
                        Delete Application
                      </MenuItem>
                    </div>
                  )}
                </Select>
              </FormControl>
            )}
            <Export callback={exportTo} />
            <button
              data-cy='refresh'
              className='btn-primary uppercase flex gap-3 items-center p-3 md:m-2'
              onClick={() => getStudents(currentPage)}
              title='Refresh students data'
            >
              <FaSync />
            </button>
          </div>
        </div>

        {state.fields && state.fields.length > 0 && (
          <>
            {userCan(data, PermissionEnum.STUDENT_LIST_CHANGE_FILTER_FIELDS) && (
              <ColumnFilter
                show={openToggleFilter}
                setOpenColumnFilter={setOpenToggleFilter}
                isToggleFilter={true}
                key={'toToggleFilters'}
                getFields={getFields}
              />
            )}
            {userCan(data, PermissionEnum.STUDENT_LIST_CHANGE_DISPLAY_COLUMNS) && (
              <ColumnFilter
                show={openColumnFilter}
                setOpenColumnFilter={setOpenColumnFilter}
                key={'toChangeColumns'}
                getFields={getFields}
              />
            )}
          </>
        )}

        <div className='overflow-auto custom-scrollbar' id='visa-student-data'>
          <table className='w-full lg:w-max' data-cy='student-table'>
            <thead>
              <tr className='[&>*]:visa-portal-theme-bg [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-center'>
                <th className='cursor-pointer'>
                  <Checkbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: '#ffffff' } }}
                    checked={selectedStudentId.length === ids.length && selectedStudentId.every((value) => ids.includes(value))}
                    onChange={(e) => {
                      selectDeselectAll(Boolean(e.target.checked))
                    }}
                  />
                </th>
                {fields
                  ?.filter((field) => column.includes(field.id))
                  .sort((a, b) => a.filterColumnOrder - b.filterColumnOrder)
                  .map((item, index) => {
                    return (
                      <React.Fragment key={`action_column_table_header_${index}`}>
                        {item.id === 'action' || item.id === 'assign_advisor' ? (
                          <th key={-2}>{item.label}</th>
                        ) : (
                          <th
                            key={index}
                            className='cursor-pointer'
                            onClick={() => {
                              shortByColumn(item.id)
                            }}
                          >
                            <div className='flex gap-3 justify-between items-center'>
                              <span className='text-nowrap'>{item.label}</span>
                              <FaSort className='size-4' />
                            </div>
                          </th>
                        )}
                      </React.Fragment>
                    )
                  })}
              </tr>
            </thead>
            <tbody>
              {students.data.length === 0 && (
                <tr>
                  <td colSpan={1000}>No Students Found</td>
                </tr>
              )}
              {Object.values(students.data).map((student: any, studentIndex: number) => {
                return (
                  <tr key={studentIndex}>
                    <td className='cursor-pointer'>
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                        onChange={() => handleCheckboxChange(student.id)}
                        checked={selectedStudentId.includes(student.id)}
                      />
                    </td>
                    {fields
                      ?.filter((field) => column.includes(field.id))
                      .sort(function (a, b) {
                        return a.filterColumnOrder - b.filterColumnOrder
                      })
                      .map((item, index) => {
                        return (
                          <React.Fragment key={`action_column_table_cell_${index}`}>
                            <td key={index} className='space-y-3'>
                              {item.id === 'advisor_id' ? (
                                student.advisor_id ? (
                                  <>
                                    {advisorList &&
                                      advisorList.map(
                                        (advisor, index) =>
                                          student.advisor_id == advisor.id && (
                                            <div key={index} className='flex '>
                                              <ImageSelector
                                                size='50px'
                                                isViewOnly={true}
                                                imageSrc={advisor.photoLink ?? noImagePlaceholder}
                                              />
                                              <label id='student-list-advisor-select-label text-nowrap' className='mx-2 self-center'>
                                                {advisor?.name}
                                              </label>
                                            </div>
                                          )
                                      )}
                                  </>
                                ) : (
                                  <label className='text-red-600 text-nowrap'>{'Advisor not  assigned'}</label>
                                )
                              ) : String(student[item.id]).startsWith('[') && String(student[item.id]).endsWith(']') ? (
                                <div className='flex flex-wrap gap-3'>
                                  {JSON.parse(student[item.id]).map((val: any, index: any) => {
                                    return (
                                      <span
                                        className='block w-full px-3 py-1 bg-slate-100 border border-slate-200 rounded-full font-semibold text-sm text-black text-nowrap max-w-min'
                                        key={index}
                                      >
                                        {val}
                                      </span>
                                    )
                                  })}
                                </div>
                              ) : typeof student[item.id] === 'string' &&
                                (String(student[item.id]).includes(IMG_BASE_URL) || String(student[item.id]).includes(GF_SIGN_BASE_URL)) ? (
                                <FileUpload
                                  fileUrl={student[item.id]}
                                  isViewOnly={true}
                                  size={50}
                                  nameVisible={item.id !== 'profile_image'}
                                />
                              ) : item.id === 'application_status' ? (
                                <div
                                  className='badge badge-primary capitalize text-center w-4/5'
                                  style={AppStatusStyle[student?.application_status?.toLowerCase()]}
                                >
                                  {student[item.id]}
                                </div>
                              ) : item.id === 'visa_portal_status' ? (
                                <div
                                  className='badge badge-primary capitalize text-center w-4/5'
                                  style={AppStatusStyle[student?.visa_portal_status?.toLowerCase() ?? StatusEnum.NEW]}
                                >
                                  {student[item.id] ?? StatusEnum.NEW}
                                </div>
                              ) : Object.keys(student.sub_steps_status)?.includes(item.id) ? (
                                student.sub_steps_status[item.id] === 'completed' ? (
                                  <img
                                    className='size-7 mx-auto'
                                    src={subStepStatusIcons.completeStepIcon}
                                    alt='Step Complete'
                                    title='Completed'
                                  />
                                ) : student.sub_steps_status[item.id] === 'recheck' ? (
                                  <img
                                    className='size-7 mx-auto'
                                    src={subStepStatusIcons.recheckStepIcon}
                                    alt='Step Needs To Be ReChecked'
                                    title='Need to be rechecked'
                                  />
                                ) : student.sub_steps_status[item.id] === 'ready_to_review' ? (
                                  <img
                                    className='size-7 mx-auto'
                                    src={subStepStatusIcons.readyToReviewStepIcon}
                                    alt='Step Ready To Review'
                                    title='Ready to review'
                                  />
                                ) : (
                                  <span className='block text-center'>-</span>
                                )
                              ) : item.id === 'due_date' ? (
                                formatDate(student.billing_and_deposit?.due_date, '-')
                              ) : item.id === 'deposit_paid_date' ? (
                                formatDate(student.billing_and_deposit?.deposit_paid_date, '-')
                              ) : item.id === 'invoice_balance_due_date' ? (
                                formatDate(student.billing_and_deposit?.invoice_balance_due_date, '-')
                              ) : item.id === 'invoice_balance_paid_date' ? (
                                formatDate(student.billing_and_deposit?.invoice_balance_paid_date, '-')
                              ) : item.id === 'action' ? (
                                <Link
                                  data-cy='student-view'
                                  className='w-full btn btn-secondary flex justify-center items-center gap-x-3'
                                  to={`/admin/visa-dashboard/${student.id}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  View <FaEye />
                                </Link>
                              ) : item.id === 'assign_advisor' ? (
                                <div className='flex gap-5 items-center'>
                                  {isAdmin(data.roles) && (
                                    <button
                                      onClick={() => {
                                        if (!multipleValue.onlyTrashed) {
                                          setSelectedStudent([student.id])
                                          setSelectedAdvisor(student.advisor_id)
                                          setConfirmModalOpen(true)
                                        }
                                      }}
                                      className={`btn-primary flex items-center gap-x-2 text-nowrap ${multipleValue.onlyTrashed ? 'opacity-50 cursor-not-allowed' : ''}`}
                                      title={multipleValue.onlyTrashed ? 'Need to Un-Archive this student first.' : ''}
                                    >
                                      Assign Advisor
                                    </button>
                                  )}
                                </div>
                              ) : isValidDate(student[item.id]) && String(student[item.id]).length >= 8 ? (
                                formatDate(student[item.id], student[item.id])
                              ) : (
                                student[item.id]
                              )}
                            </td>
                          </React.Fragment>
                        )
                      })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {Boolean(students?.data?.length ?? 0 > 0) && (
          <div className='flex items-center justify-between mr-5 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-[var(--theme-color)] [&>*_a:hover]:text-white [&>*_li.active>a]:visa-portal-theme-bg [&>*_li.active>a]:text-white'>
            <ReactJsPagination
              activePage={students?.currentPage || 1}
              itemsCountPerPage={students?.perPage}
              totalItemsCount={students?.total || 0}
              pageRangeDisplayed={students?.to}
              onChange={(pageNumber) => {
                getStudents(pageNumber)
              }}
              activeClass={`active ${students?.currentPage == 1 && students?.lastPage == 1 && 'pointer-events-none'}`}
            />
            <label className=' select-none'>{`Page ${students?.currentPage} of ${students?.lastPage}`}</label>
          </div>
        )}
      </div>
      <ConfirmationModal
        isConfirmModalOpen={isConfirmModalOpen}
        setConfirmModalOpen={(value: boolean) => {
          setConfirmModalOpen(value)
          if (!value) {
            setSelectedAdvisor(null)
          }
        }}
        deleteButtonText='Assign'
        advisors={advisorList}
        onAction={changeAdvisorInBulk}
        title={'Assign Advisor'}
        assignedAdvisor={selectedAdvisor}
      />
      <ConfirmationAlert
        title={`${action === 'deleteApplication' ? 'This action can not be reversed. \n' : ''} Are you sure you perform this action ?`}
        isConfirmModalOpen={isConfirmAlertOpen}
        setConfirmModalOpen={setConfirmAlertOpen}
        ButtonText={'Yes I am sure'}
        actionName={actionLabel}
        onAction={() => {
          performAction()
        }}
      />
    </div>
  )
}
