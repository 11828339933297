import { uploadFile } from 'CommonJS/common'
import { toast } from 'Components/Toast/toast'
import { FileUploadResponse } from 'Context/AppTypes'

export const TinyMcePlugins = [
  'advlist',
  'anchor',
  'autolink',
  'charmap',
  'code',
  'fullscreen',
  'help',
  'image',
  'insertdatetime',
  'link',
  'lists',
  'media',
  'preview',
  'searchreplace',
  'table',
  'visualblocks',
  'accordion',
  'autosave',
  'charmap',
  'codesample',
  'directionality',
  'emoticons',
  // 'importcss'
  'insertdatetime',
  'nonbreaking',
  'pagebreak',
  'quickbars',
  'save',
  'visualchars',
  'wordcount'
]

export const TinyMceToolbar = [
  'save undo redo |preview link image media accordion | styles | bold italic underline strikethrough emoticons nonbreaking pagebreak| align | bullist numlist|anchor|code codesample|ltr rtl|fullscreen|insertdatetime| help',
  'visualblocks visualchars| table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol| wordcount'
  //   insertdatetime_dateformat: '%d-%m-%Y'
]
interface BlobInfo {
  id: () => string
  name: () => string
  filename: () => string
  blob: () => Blob
  base64: () => string
  blobUri: () => string
  uri: () => string | undefined
}
type ProgressFn = (percent: number) => void

function uploadHandler(blobInfo: BlobInfo, progress: ProgressFn): Promise<string> {
  progress(50)
  return new Promise<string>((resolve, reject) => {
    uploadFile(blobInfo.blob() as File)
      .then((response: FileUploadResponse) => {
        progress(70)
        if (response.success) {
          progress(90)
          toast(response.message as string, 'success')
        }
        progress(100)
        resolve(response.document?.fullPath as string)
      })
      .catch((e: any) => {
        toast(e.message ?? 'Something went wrong while upload image.', 'error')
        progress(100)
        reject('Error while uploading error')
      })
  })
}

export const TinyMceCustomizationOptions = {
  relative_urls: false,
  convert_urls: false,
  remove_script_host: false,
  quickbars_insert_toolbar: 'quicktable image media codesample',
  quickbars_selection_toolbar: 'bold italic underline | blocks | bullist numlist | blockquote quicklink',
  contextmenu: 'undo redo | inserttable | cell row column deletetable | help',
  save_onsavecallback: (instance: any) => instance,
  images_upload_handler: uploadHandler,
  file_picker_types: 'file',
  file_picker_callback: function (cb: any) {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')

    input.addEventListener('change', (e: any) => {
      const file = (e.target as HTMLInputElement)?.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          return new Promise<string>((resolve, reject) => {
            uploadFile(file as File)
              .then((response: FileUploadResponse) => {
                if (response.success) {
                  toast(response.message as string, 'success')
                }
                resolve(response.document?.fullPath as string)
                cb(response.document?.fullPath, { title: file.name })
              })
              .catch((e: any) => {
                toast(e.message ?? 'Something went wrong while upload image.', 'error')
                reject('Error while uploading error')
              })
          })
        })
        reader.readAsDataURL(file)
      }
    })
    input.click()
  }
}
